import React, { useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { IconButton, TextField, Typography } from '@mui/material';
import {
  FirstPage as FirstPageIcon,
  KeyboardArrowLeft,
  KeyboardArrowRight,
  LastPage as LastPageIcon
} from '@mui/icons-material';
import * as constants from 'src/utils/constants/stringConstants';
import { MAX_PAGE_COUNT_LENGTH } from 'src/utils/constants/numericConstants';
import clsx from 'clsx';
import { LARGE_SIZE, SMALL_SIZE } from 'src/utils/constants/stringConstants';
import useStyles from './styles';
import { onPressEnter } from '../../utils/utils';

export const TablePaginationActions = (props) => {
  const classes = useStyles();
  const {
    count,
    page: currentPage,
    rowsPerPage,
    onPageChange,
    size,
    pageCounter,
    disabled
  } = props;
  const [page, setPage] = useState(currentPage);

  useEffect(() => {
    setPage(currentPage);
  }, [currentPage]);

  const handleFirstPageButtonClick = () => {
    onPageChange(1);
  };

  const handleBackButtonClick = () => {
    onPageChange(page - 1);
  };

  const handleNextButtonClick = () => {
    onPageChange(page + 1);
  };

  const handleLastPageButtonClick = () => {
    onPageChange(Math.max(0, Math.ceil(count / rowsPerPage)));
  };

  const isSmallComponent = useMemo(() => !!size && size === SMALL_SIZE, [size]);

  const handleChange = (event) => {
    const noStrings = event.target.value.replace(/\D+/g, ' ');
    let refactoredPage = noStrings.replace(/[[\-!$%^&*()_+|~=`{}\]:";'<>?,./\\]|\s]/gi, '');
    if (!!pageCounter && Number(refactoredPage) > pageCounter) {
      refactoredPage = page;
    }
    setPage(+refactoredPage);
  };

  const handlePageChange = () => {
    if (!page || page === 0) {
      setPage(currentPage);
    } else {
      onPageChange(page);
    }
  };

  return (
    <div className={clsx(classes.root, isSmallComponent && classes.rootSmall)}>
      <span>
        <Typography
          variant="body2"
          className={clsx(classes.choosePage, isSmallComponent && classes.choosePageSmall)}
        >
          Start from page:
        </Typography>
        <span>
          <TextField
            disabled={disabled}
            size="small"
            error={!page || page === 0}
            helperText={!page || page === 0 ? constants.PAGE_FIRST : null}
            className={clsx(classes.pageInput, isSmallComponent ? classes.pageInputSmall : '')}
            value={page === 0 ? '' : page}
            onChange={handleChange}
            onBlur={handlePageChange}
            onKeyDown={onPressEnter(handlePageChange)}
            inputProps={{
              maxLength: MAX_PAGE_COUNT_LENGTH
            }}
          />
        </span>
        {!!pageCounter && <span className={classes.pageCounter}>of {pageCounter}</span>}
      </span>
      <IconButton
        onClick={handleFirstPageButtonClick}
        disabled={!page || +page === 1 || disabled}
        aria-label="first page"
        data-testid="first_page"
        size={size || LARGE_SIZE}
      >
        <FirstPageIcon />
      </IconButton>
      <IconButton
        onClick={handleBackButtonClick}
        disabled={!page || +page === 1 || disabled}
        aria-label="previous page"
        data-testid="previous_page"
        size={size || LARGE_SIZE}
      >
        <KeyboardArrowLeft />
      </IconButton>
      <IconButton
        onClick={handleNextButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) || disabled}
        aria-label="next page"
        data-testid="next_page"
        size={size || LARGE_SIZE}
      >
        <KeyboardArrowRight />
      </IconButton>
      <IconButton
        onClick={handleLastPageButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) || disabled}
        aria-label="last page"
        data-testid="last_page"
        size={size || LARGE_SIZE}
      >
        <LastPageIcon />
      </IconButton>
    </div>
  );
};

TablePaginationActions.propTypes = {
  count: PropTypes.number.isRequired,
  onPageChange: PropTypes.func.isRequired,
  page: PropTypes.number.isRequired,
  rowsPerPage: PropTypes.number.isRequired,
  size: PropTypes.string,
  pageCounter: PropTypes.number,
  disabled: PropTypes.bool
};
